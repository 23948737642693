@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@1,300&display=swap");
html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;

  /* Give app full page to work with */
  height: 100vh;
}

#page-wrap {
  text-align: left;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 30px;
  top: 26px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(55, 58, 71, 0.8);
  border-bottom-left-radius: 20%;
  border-top-left-radius: 20%;
  border-bottom-left-radius: 20%;
  padding: 2.5em 1.5em 0;
  font-size: 1.25em;
  color: white !important;
  margin-top: 40px;
  margin-left: 20% !important;
  max-width: 250px;
}

@media (max-width: 600px) {
  .bm-menu {
    background: rgba(55, 58, 71, 0.8);
    border-bottom-left-radius: 20%;
    border-top-left-radius: 20%;
    border-bottom-left-radius: 20%;
    padding: 2.5em 1.5em 0;
    font-size: 1.25em;
    color: white !important;
    margin-top: 40px;
    margin-left: -145%;
    padding-left: 28% !important;
    max-width: 250px;
  }
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    margin-top: 40px;
    margin-left: -96% !important;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #fff;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  margin-top: 40px;
  margin-left: -65px;
}
@media (min-width: 600px) {
  .try2 {
    display: none;
  }
}
@media (max-width: 600px) {
  .try1 {
    display: none;
  }
}

html,
body {
  /* max-width: 100%; */
  /* overflow-x: hidden; */
  background-color: white;
}
#bodyAfterNav {
  overflow-x: hidden;
}
.header {
  position: sticky;
  left: 0px;
  top: 0px;
  min-height: 80px;
  background: #000000;
  box-shadow: 0px 4px 40px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow-x: auto;
}

.header a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  color: #2c3e50 !important;
}

#header-logo {
  height: 50px;
  width: 50px;
}

@media only screen and (max-width: 600px) {
  /* #header-img {
    display: none;
  } */

  #header-logo {
    display: inline;
  }
}

.navbar-light .navbar-toggler {
  border: none !important;
}

.section-top {
  border-left: 6px solid #2c3e50;
  margin: 20px 40px;
  padding: 10px 10px;
}
.section-top1 {
  margin-left: 20px;
  padding-bottom: 3%;
}

@media only screen and (max-width: 600px) {
  .section-top {
    border-left: 6px solid #2c3e50;
    margin: 20px 10px;
    padding: 10px 10px;
  }
  .section-top1 {
    border-left: 6px solid #2c3e50;
    margin: 20px 10px;
    padding: 10px 10px;
    margin-top: -2%;
    margin-bottom: 0%;
  }
  .try3 {
    padding-left: 10% !important;
  }
  .try4 {
    margin-left: -3.2% !important;
  }
}
@media (min-width: 600px) {
  .try3 {
    padding-right: 4% !important;
  }
}

.section-top-right {
  border-right: 6px solid #2c3e50;
  margin: 10px 40px;
  padding: 10px 10px;
  text-align: right;
}

.section-top-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 44px;
  font-variant: small-caps;
  color: #2c3e50;
}

/* Carousel */

.carousel-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 44px;
  font-variant: small-caps;
  color: #ffffff;
  text-align: center;
  padding: 30px 0;
  padding-bottom: 0;
}

.carousel-content {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  color: #ffffff;
  padding: 80px 40px;
  padding-top: 40px;
  text-align: justify;
}

.carousel-content a {
  color: #fff;
  font-weight: 200;
  text-decoration: underline;
}

.carousel-control-prev-icon {
  background-image: none !important;
}

.carousel-control-next-icon {
  background-image: none !important;
}

/* Card  */

.common-card {
  background-color: #f2f2f2;
  display: flex;
  padding: 10px;
  margin: 5px;
}

.common-card-paragraph {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  letter-spacing: 0.025em;
  margin: 0 10px;
  color: #000000;
}

.common-card-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  font-variant: small-caps;
  color: #2c3e50;
  padding: 20px 16px;
}

.common-card-button {
  width: 120px;
  height: 38px;
  border: 3px solid #2c3e50;
  box-sizing: border-box;
  border-radius: 100px;
  margin: 20px;
}

.common-card-img {
  height: 100%;
  width: 100%;
}

/* Slide  */
.slide-img {
  height: 400px;
  width: 800px;
}

@media only screen and (max-width: 600px) {
  .slide-img {
    height: 200px;
    width: 400px;
  }
}

/* button */

button a {
  color: white;
}
button a:hover {
  color: white;
  text-decoration: none;
}

button {
  transition: transform 0.2s;
}

button:hover {
  transform: scale(1.1);
}

/* Section 1 */

.section1-text {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 210px;
  line-height: 201px;
  color: #f2f2f2;
  text-align: center;
  padding-top: 13% !important;
  /* margin-top: 10% !important;
  margin-bottom: 8% !important; */
}

.mobile {
  display: none;
}

.section1-img-div {
  display: flex;
  justify-content: center;
}

#section1-img {
  position: absolute;
  z-index: 99;
  height: 103vh;
  margin: auto;
  margin-top: -21px;
  padding-bottom: px;
}
.try6 {
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
}

@media only screen and (max-width: 600px) {
  .section1-text {
    font-size: 60px;
    line-height: 70px;
    margin-top: 50px;
    /* margin-bottom: 30% !important; */
  }

  .section1-img-div {
    display: flex;
    justify-content: top;
    align-items: flex-start;
  }

  .section1-div {
    display: flex;
    /* justify-content: top;
    align-items: flex-start; */
  }

  #section1-img {
    position: absolute;
    z-index: 99;
    height: 80vh;
    margin: auto;
    margin-top: 81px;
  }

  .mobile {
    display: block;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1200) {
  .section1-text {
    font-size: 120px;
    line-height: 120px;
  }
}

/* Section 2 */

.section2-card {
  background-color: #f2f2f2;
  padding: 10px;
  margin: 10px 10px;
  min-height: 200px;
}

@media only screen and (max-width: 600px) {
  .section2-card {
    margin: 10px 0px;
    min-height: 200px;
    min-width: 300px;
    text-align: center;
  }
}

.section2-card-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 44px;
}

.section2-card-paragraph {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}

.section2-card-button {
  height: 45px;
  width: 180px;
  border-radius: 100px;
}

@media only screen and (max-width: 600px) {
  .section2-icon-mobile {
    display: block;
  }

  .section2-icon {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .section2-icon-mobile {
    display: none;
  }

  .section2-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section2-card {
    display: flex;
  }
}

/* Section 3 */

.section3 {
  padding: 0px 40px;
  margin-right: 0;
  margin-top: 10px;
}

.section3-left {
  padding: 40px 0;
}

.section3-heading {
  font-family: Playfair Display;
  text-align: left;
  color: #d5820d;
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 85px;
  margin: 20px 0px;
}

.section3-text {
  font-family: Mulish;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-align: justify;
  color: #2c3e50;
}

.section3-button {
  background: #2c3e50;
  backdrop-filter: blur(15px);
  border: none;
  border-radius: 100px;
  width: 200px;
  height: 56px;
  margin: 60px 60px 20px 0px;
  color: #fff;
  font-size: 20px;
}

.section3-lower {
  display: flex;
  justify-content: flex-start;
}
#section3-img {
  margin-top: 12px !important;
}

@media only screen and (max-width: 600px) {
  #section3-img {
    display: none;
  }

  .section3-button {
    border: none;
    border-radius: 100px;
    margin: 60px 60px 0px 0px;
    width: 300px;
  }

  .section3-lower {
    display: block;
    text-align: center;
  }
}

/* Section 4 */

.section4 {
  text-align: center;
  background-color: #2c3e50;
}

/* Section 5 */

.section5-border-left {
  width: 280px;
  border-bottom: 6px solid #fff;
  margin-left: 30px;
}

.section5-border-right {
  width: 280px;
  border-bottom: 6px solid #fff;
  margin-left: 30px;
  float: right;
  margin-right: 1.7rem;
  /* direction: rtl; */
}

.section5-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 44px;
  font-variant: small-caps;
  padding: 40px 30px;
  padding-bottom: 0;
}

.section5-content {
  font-family: "Playfair Display", serif;
  padding: 60px 30px;
  font-size: 16px;
  line-height: 32px;
  font-weight: lighter;
  text-align: justify;
}

/* Sction 7 */

.section7-cards {
  margin: 20px 40px !important;
}

.section7-button {
  background: #2c3e50;
  border: none;
  border-radius: 100px;
  width: 150px;
  height: 46px;
  color: #fff;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .section7-cards {
    margin: 20px 0px !important;
  }
}

/* Section 8 */
#FlagImage {
  height: 100%;
  width: 92%;
  margin-left: 5%;
}
/* .section8-left-section {
  background: url("https://images.unsplash.com/photo-1532375810709-75b1da00537c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60");

  height: 100%;

  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

/* Section 9 */

.section9 {
  /* background-image: url("https://images.unsplash.com/photo-1496262967815-132206202600?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1095&q=80");
  background-repeat: round;
  width: 100%; */
  background-color: rgb(213, 130, 13) !important;
}

.section9-left-content {
  padding: 100px 100px;
  text-align: center;
  /* background-color: rgb(213, 130, 13) !important; */
}

.section9-left-content a {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.5);
}

.section9-right-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section9-right-content img {
  height: 450px;
  width: 450px;
}

.section9-text {
  font-family: Playfair Display;
  font-size: 50px;
  line-height: 85px;
  color: #fff;
}

.section9-button {
  width: 350px;
  height: 60px;
  background-color: #2c3e50;
  /* background: linear-gradient(0deg, #d5820d, #d5820d), #c06a2b; */
  border-radius: 100px;
  border: none;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #f2f2f2;
  margin: 50px 0;
}

@media only screen and (max-width: 600px) {
  .section9-right-content img {
    display: none;
  }

  .section9-button {
    width: 200px;
    height: 60px;
    margin: 40px;
  }
}

/* Section 10 */

.section-10 {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 40px;
  margin-right: 40px;
}

@media only screen and (max-width: 600px) {
  .section-10 {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.page-section {
  margin: 40px 40px;
  padding: 30px;
  background: #f2f2f2;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}

/* Contact form */

.contact-form {
  margin: 5% 20%;
}

.contact-form-heading {
  font-size: 45px;
  margin-top: 50px;
}

.contact-form-fields {
  margin-top: 20px;
}

textarea:focus,
input[type="text"]:focus,
input[type="email"]:focus {
  border-color: #d5820d;
  box-shadow: 0 1px 1px #d5820d inset, 0 0 8px #d5820d;
  outline: 0 none;
}

.contact-form-button {
  background: #d5820d;
  backdrop-filter: blur(15px);
  border-radius: 100px;
  border: none;
  width: 150px;
  height: 46px;
  color: #fff;
  font-size: 18px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .contact-form {
    margin: 5% 10px;
  }
}

/* footer */

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  background-color: #2c3e50;
  color: #fff;
  height: 50px;
  text-align: center;
}

/*BLOGS */

#BlogContent img {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  height: 30rem;
}
@media only screen and (max-width: 600px) {
  #BlogContent img {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 15rem;
  }
}
#BlogContent {
  font-family: Playfair Display;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  text-align: justify;
  text-justify: inter-word;
}

#BlogSubHeading {
  font-family: Playfair Display;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 2em;
}

#BlogTitle {
  font-family: Playfair Display;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  text-align: center;
  margin-top: 1em;
}

#BlogCard {
  /* margin-top: 10em; */
  font-family: Playfair Display !important;
  margin-top: 2em;
}
#CardForBlog {
  min-height: 16rem;
}
#BlogCardTitle {
  height: 5rem;
  font-size: 20px;
}
#BlogImageDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
#BlogImage {
  height: 15rem;
  width: 15rem;
}
@media only screen and (max-width: 600px) {
  #BlogImage {
    height: 10rem;
    width: 10rem;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1250px) {
  #BlogImage {
    height: 10rem;
    width: 10rem;
  }
}
/* #BlogCardSubHeading {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  height: 6rem; 
} */
#BlogCardSubHeading p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-left: 1.3rem;
  padding-right: 0.3rem;
}
#BlogCardTitle p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.MuiTypography-root {
  margin: 0;
  font-family: Playfair Display !important;
}
#BlogTop {
  margin-top: 2em;
}
#BlogBottom {
  margin-bottom: 6em;
}

#loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff url("./Assets/animation.gif") no-repeat center;
  z-index: 1;
  overflow: visible;
}
@media only screen and (max-width: 620px) {
  #loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff url("./Assets/ezgif.com-gif-maker.gif") no-repeat center;
    z-index: 1;
    overflow: visible;
  }
}
@media only screen and (max-width: 460px) {
  #loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff url("./Assets/animation-small.gif") no-repeat center;
    z-index: 1;
    overflow: visible;
  }
}
.InsideImagesRight {
  /* business */
  width: 80%;
  padding-top: 2.6rem;
  padding-right: 1rem;
  margin-left: 5rem;
}
.InsideImagesRight2 {
  /* hindusism */
  width: 80%;
  padding-top: 2.6rem;
  padding-right: 1rem;
  margin-left: 5rem;
}

.InsideImagesLeft {
  width: 50%;
  padding-top: 1rem;
  padding-left: 2.5rem;
  padding-right: 5rem;
}
.InsideImagesLeft2 {
  width: 90%;
  padding-top: 2.6rem;
  padding-left: 2.7rem;
}

.SmallSizeDiv {
  display: none;
}

.NormalSizeDiv {
  display: block;
}
@media only screen and (max-width: 575px) {
  .InsideImagesRight {
    width: inherit;
    padding-top: 2.6rem;
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .InsideImagesLeft {
    width: inherit;
    padding-top: 2.6rem;
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .SmallSizeDiv {
    display: block;
  }

  .NormalSizeDiv {
    display: none;
  }
}

.ct-socials {
  position: fixed;
  top: 40%;
  right: 0;

  list-style: none;
  padding-left: 0;
  z-index: 10;
  margin: 0;
  -webkit-transition: right 0.25s ease-in-out;
  transition: right 0.25s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ct-socials li {
  padding: 0px 0;
}
.ct-socials li a {
  background: #3f4653;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  width: 34px;
  height: 54px;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  float: right;
  padding-left: 5px;
}
/* .ct-socials li a:hover {
      width: 54px;
      padding-left: 0;
      text-decoration: none;
      background: #3c9bd9;
} */
.FourCardImage {
  height: 11rem;
}
@media only screen and (max-width: 600px) {
  .FourCardImage {
    height: 7rem;
  }
}

/*Section-1 Gradient*/
.blackBgSection1 {
  background-image: url("../src/Assets/Pic1.png");
  /* width: 100%;
  height: 300px; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background-color: #000000; */
  /* background-position: 20% 30%; */
}
@media only screen and (max-width: 600px) {
  .blackBgSection1 {
    height: 30vh !important;
    top: 80px !important;
  }
}
@media only screen and (min-width: 600px) {
  .blackBgSection1 {
    padding-bottom: 12.5%;
    height: 95vh !important;
    top: 80px !important;
  }
}

/* .react-video {
  margin: 40px auto;
}

@media only screen and (max-width: 600px) {
  .react-video {
    margin: 40px 10px;
  }
} */
